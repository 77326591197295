import {withNamespaces, WithNamespaces} from 'react-i18next'
import {downloadTicketsAction} from '../../../actions/tickets'
import {downloadTickets} from '../../../services/tickets'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {MobileDownloadTicketsButton as DownloadTicketsButtonPresentation} from './mobile-download-tickets-button'

export interface MobileDownloadTicketsButtonProps
  extends MobileDownloadTicketsButtonOwnProps,
    MobileDownloadTicketsButtonRuntimeProps,
    WithNamespaces {}

interface MobileDownloadTicketsButtonOwnProps {
  order: wix.events.ticketing.Order
}

interface MobileDownloadTicketsButtonRuntimeProps {
  downloadTickets: typeof downloadTickets
  downloadTicketsAction: typeof downloadTicketsAction
}

const mapRuntime = ({actions}: MemberPageContext) => ({
  downloadTickets,
  downloadTicketsAction: actions.downloadTicketsAction,
})

export const MobileDownloadTicketsButton = connect<
  MobileDownloadTicketsButtonOwnProps,
  MobileDownloadTicketsButtonRuntimeProps
>(mapRuntime)(withNamespaces()(DownloadTicketsButtonPresentation))
