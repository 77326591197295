import {ExperimentsProvider} from '@wix/wix-experiments-react'
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'
import {TPAComponentsProvider} from 'wix-ui-tpa/dist/src/components/TPAComponentsConfig'
import {ErrorPage} from '../../../commons/components/error-page'
import {RuntimeContext} from '../../../commons/components/runtime-context/runtime-context'
import {isMobile, isPreview} from '../../../commons/selectors/environment'
import {ssrI18N} from '../../../commons/services/i18n'
import {MemberAppProps} from '../../interface'
import {shouldLoadMore} from '../../selectors/events'
import {resizeHeight} from '../../services/resize'
import {AppContainer} from '../app-container'
import {AppLoaded} from '../app-loaded'

const SCROLL_CUTOFF = 0.95

export default class AppRoot extends React.Component<MemberAppProps> {
  componentDidMount(): void {
    this.props.host.registerToScroll(({progressY}) => {
      if (progressY > SCROLL_CUTOFF && shouldLoadMore(this.props.state)) {
        this.props.actions.getEvents(this.props.state.tab)
      }
    })
  }

  componentDidUpdate(prevProps: MemberAppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }

  resize = () => {
    resizeHeight(this.props.state)
  }

  render() {
    if (!this.props.state) {
      return <ErrorPage />
    }

    const {
      state: {experiments},
      translations,
    } = this.props
    const mobile = isMobile(this.props.state)

    return (
      <RuntimeContext.Provider value={this.props}>
        <TPAComponentsProvider value={{mobile}}>
          <I18nextProvider i18n={ssrI18N(translations)}>
            <ExperimentsProvider options={{experiments}}>
              <>
                <AppLoaded host={this.props.host} />
                <AppContainer resize={this.resize} isRTL={this.props.isRTL} />
              </>
            </ExperimentsProvider>
          </I18nextProvider>
        </TPAComponentsProvider>
      </RuntimeContext.Provider>
    )
  }
}
