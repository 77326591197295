import * as React from 'react'
import {BUTTON_SHARE} from '../../../config/data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {ShareButtonProps} from './index'

export const ShareButton = ({mobile, shareEvent, event, t}: ShareButtonProps) => (
  <EventLink mobile={mobile} onClick={() => shareEvent(event)} data-hook={BUTTON_SHARE}>
    {t('events.buttons.share-event')}
  </EventLink>
)
