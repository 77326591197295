import * as React from 'react'
import {MOBILE_BUTTON_DOWNLOAD_TICKETS} from '../../../config/data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {MobileDownloadTicketsButtonProps} from './index'

export const MobileDownloadTicketsButton = ({
  downloadTickets,
  order,
  t,
  downloadTicketsAction,
}: MobileDownloadTicketsButtonProps) => {
  const handleEventLinkClick = () => {
    downloadTicketsAction(order.eventId)
    downloadTickets(order)
  }

  return (
    <EventLink primary mobile={true} onClick={handleEventLinkClick} data-hook={MOBILE_BUTTON_DOWNLOAD_TICKETS}>
      {t('events.buttons.download-tickets')}
    </EventLink>
  )
}
