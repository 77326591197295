import * as classNames from 'classnames'
import * as React from 'react'
import {EXPANDABLE_ROW, EXPANDABLE_ROW_ICON} from '../../config/data-hooks'
import {Divider} from '../styled-components/divider'
import {ExpandIcon} from './expand-icon/expand-icon'
import s from './expandable-row.scss'
import {ExpandableRowProps} from '.'

export class ExpandableRow extends React.Component<ExpandableRowProps> {
  render() {
    const {children, details, isExpanded, toggle, arrowClass, mobile, href, navigable, divider, t} = this.props
    const showDivider = divider === undefined || divider
    return (
      <div className={classNames(s.root, {[s.mobile]: mobile})} data-hook={EXPANDABLE_ROW}>
        <div className={s.content}>
          <div className={s.container}>
            <div className={s.summary}>{children}</div>
            <div className={s.expandIcon}>
              <ExpandIcon
                mobile={mobile}
                extraClass={arrowClass}
                expanded={isExpanded}
                onClick={toggle}
                href={href}
                right={navigable}
                dataHook={EXPANDABLE_ROW_ICON}
                t={t}
              />
            </div>
          </div>
          {isExpanded ? details : null}
        </div>
        {showDivider ? <Divider /> : null}
      </div>
    )
  }
}
