import {MembersTexts} from '../../../commons/types/members-settings'
import {getUserText} from '../../selectors/component'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {Header as HeaderPresentation} from './header'

export interface HeaderProps extends HeaderRuntimeProps {}

interface HeaderRuntimeProps {
  title: string
  subtitle: string
}

const mapRuntime = ({state}: MemberPageContext): HeaderRuntimeProps => ({
  title: getUserText(state, MembersTexts.pageTitle),
  subtitle: getUserText(state, MembersTexts.description),
})

export const Header = connect<{}, HeaderRuntimeProps>(mapRuntime)(HeaderPresentation)
