import {getCheckoutSummary, getFormattedMoney, getTaxLabel, getYearMonthDay} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {BrowserOnly} from '../../../commons/components/browser-only'
import * as DH from '../../config/data-hooks'
import {Routes} from '../../types/state'
import {MobileDownloadTicketsButton} from '../actions/mobile-download-tickets-button'
import {Back} from '../back'
import {Divider} from '../styled-components/divider'
import {EventText} from '../styled-components/event-text'
import * as s from './mobile-order.scss'
import {MobileOrderProps} from '.'

export class MobileOrder extends React.Component<MobileOrderProps> {
  render() {
    const {order, locale, timezone, internalNavigate, event, t} = this.props
    const {orderNumber, created, invoice} = order
    const {items, grandTotal} = invoice
    const total = getFormattedMoney(grandTotal)
    return (
      <div className={s.root}>
        <Back onClick={() => internalNavigate(Routes.ORDER_LIST, {event})} t={t} />
        <div className={s.section}>
          <div className={s.title}>
            <EventText extraClass={s.title}>{t('mobile.order.details.title')}</EventText>
          </div>
          <div className={s.orderNumber} data-hook={DH.MOBILE_ORDER_DETAILS_NUMBER}>
            <EventText faded extraClass={s.text}>
              {t('mobile.order.number', {orderNumber})}
            </EventText>
          </div>
          <div className={s.date}>
            <BrowserOnly>
              <EventText faded extraClass={s.text}>
                {t('mobile.order.date', {date: getYearMonthDay(created, timezone, locale)})}
              </EventText>
            </BrowserOnly>
          </div>
          <div className={s.total} data-hook={DH.MOBILE_ORDER_DETAILS_TOTAL}>
            <EventText extraClass={s.text}>{t('mobile.order.total', {total})}</EventText>
          </div>
          <div className={classNames(s.button, s.largeText)}>
            <MobileDownloadTicketsButton order={order} />
          </div>
        </div>
        <Divider />
        {items.map(item => (
          <Ticket item={item} t={t} key={item.id} />
        ))}
        <Subtotals invoice={invoice} t={t} />
      </div>
    )
  }
}

const Ticket = ({item, t}) => {
  const {name, price, quantity, total} = item
  return (
    <>
      <div className={s.section} data-hook={DH.TICKET}>
        <div>
          <EventText extraClass={s.text}>{name}</EventText>
        </div>
        <div className={classNames(s.price, s.splitRow)}>
          <EventText faded extraClass={s.text}>
            {t('mobile.order.ticket.price')}
          </EventText>
          <EventText faded extraClass={s.text}>
            {getFormattedMoney(price)}
          </EventText>
        </div>
        <div className={classNames(s.quantity, s.splitRow)}>
          <EventText faded extraClass={s.text}>
            {t('mobile.order.ticket.quantity')}
          </EventText>
          <EventText faded extraClass={s.text}>
            {quantity}
          </EventText>
        </div>
        <div className={classNames(s.ticketTotal, s.splitRow)} data-hook={DH.TICKET_TOTAL}>
          <EventText extraClass={s.text}>{t('mobile.order.ticket.total')}</EventText>
          <EventText extraClass={s.text}>{getFormattedMoney(total)}</EventText>
        </div>
      </div>
      <Divider />
    </>
  )
}

const Subtotals = ({invoice, t}) => {
  const {subtotal, discount, tax, addedFee, total} = getCheckoutSummary(invoice)
  const taxLabel = getTaxLabel(invoice)

  return (
    <>
      {subtotal && (
        <>
          <div className={s.section} data-hook={DH.SUBTOTALS_CONTAINER}>
            <Price value={subtotal} label={t('mobile.order.subtotal')} dataHook={DH.SUBTOTAL} />
            <Price value={discount} label={t('mobile.order.coupon')} dataHook={DH.DISCOUNT_TOTAL} />
            <Price value={tax} label={taxLabel} dataHook={DH.TAX} />
            <Price value={addedFee} label={t('order.serviceFee')} />
          </div>
          <Divider />
        </>
      )}
      <div className={classNames(s.overallTotal, s.splitRow)}>
        <EventText extraClass={s.largeText}>{t('mobile.order.overall.total')}</EventText>
        <EventText extraClass={s.largeText}>{total}</EventText>
      </div>
    </>
  )
}

const Price = ({value, label, dataHook = null}) => {
  if (!value) {
    return null
  }

  return (
    <div className={classNames(s.splitRow, s.subtotalRow)} data-hook={dataHook}>
      <EventText extraClass={s.text}>{label}</EventText>
      <EventText extraClass={s.text}>{value}</EventText>
    </div>
  )
}
