import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary'
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles'
import {ErrorPage} from '../../../commons/components/error-page'
import {getMembersPageSentryConfig} from '../../utils/sentry'
import App from '.'

export default {
  component: withSentryErrorBoundary(
    withStyles(App, {
      cssPath: ['membersViewerWidget.stylable.bundle.css', 'membersViewerWidget.css'],
    }),
    getMembersPageSentryConfig('events-member-page-viewer'),
    ErrorPage,
  ),
}
