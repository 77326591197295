import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../commons/selectors/environment'
import {closeAllEvents, toggleEventDetails} from '../../actions/events'
import {getEventList, hasNoEvents} from '../../selectors/events'
import {isOwnProfile} from '../../selectors/user'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventsList as EventsListPresentation} from './events-list'

export interface EventsListProps extends EventsListRuntimeProps, WithNamespaces {}

interface EventsListRuntimeProps {
  hasNoEvents: boolean
  events: DetailedEvent[]
  expandedEvents: string[]
  loading: boolean
  isOwner: boolean
  mobile: boolean
  toggleEventDetails: typeof toggleEventDetails
  closeAllEvents: typeof closeAllEvents
}

const mapRuntime = ({state, actions}: MemberPageContext): EventsListRuntimeProps => ({
  hasNoEvents: hasNoEvents(state),
  expandedEvents: state.expandedEvents,
  toggleEventDetails: actions.toggleEventDetails,
  loading: state.loading,
  isOwner: isOwnProfile(state),
  mobile: isMobile(state),
  events: getEventList(state),
  closeAllEvents: actions.closeAllEvents,
})

export const EventsList = connect<{}, EventsListRuntimeProps>(mapRuntime)(withNamespaces()(EventsListPresentation))
