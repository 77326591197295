import {getFormattedMoney, getYearMonthDay} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {MOBILE_ORDER_DATE, MOBILE_ORDER_NUMBER, MOBILE_ORDER_TOTAL} from '../../config/data-hooks'
import {Routes} from '../../types/state'
import {Back} from '../back'
import {ExpandableRow} from '../expandable-row'
import {EventText} from '../styled-components/event-text'
import * as s from './mobile-order-list.scss'
import {MobileOrderListProps} from '.'

export const MobileOrderList = (props: MobileOrderListProps) => {
  const {orders, event, internalNavigate, t} = props
  return (
    <div className={s.root}>
      <Back onClick={() => internalNavigate(Routes.DEFAULT)} t={t} />
      {orders.map((order: wix.events.ticketing.Order, idx: number) => (
        <OrderRow
          key={order.orderNumber}
          order={order}
          lastRow={idx === orders.length - 1}
          onClick={() => internalNavigate(Routes.ORDER, {event, order})}
          {...props}
        />
      ))}
    </div>
  )
}

const OrderRow = ({order, timezone, lastRow, locale, onClick, t}) => {
  const {orderNumber, created} = order
  return (
    <ExpandableRow
      mobile
      navigable
      key={order.orderNumber}
      divider={!lastRow}
      toggle={onClick}
      arrowClass={s.arrow}
      t={t}
    >
      <div className={s.content}>
        <div data-hook={MOBILE_ORDER_NUMBER}>
          <EventText extraClass={s.text}>{t('mobile.order.number', {orderNumber})}</EventText>
        </div>
        <div className={s.date} data-hook={MOBILE_ORDER_DATE}>
          <BrowserOnly>
            <EventText faded extraClass={s.text}>
              {t('mobile.order.date', {date: getYearMonthDay(created, timezone, locale)})}
            </EventText>
          </BrowserOnly>
        </div>
        <div className={s.total} data-hook={MOBILE_ORDER_TOTAL}>
          <EventText extraClass={s.text}>
            {t('mobile.order.total', {total: getFormattedMoney(order.invoice.grandTotal)})}
          </EventText>
        </div>
      </div>
    </ExpandableRow>
  )
}
