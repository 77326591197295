import * as classNames from 'classnames'
import * as React from 'react'
import {Text, TYPOGRAPHY} from 'wix-ui-tpa/Text'
import s from './event-text.st.css'

export const EventText = props => (
  <Text
    typography={TYPOGRAPHY.runningText}
    {...props}
    className={classNames(
      s.root,
      {[s.faded]: props.faded, [s.large]: props.large},
      props.extraClass ? props.extraClass : null,
    )}
  />
)
