import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../commons/selectors/environment'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {OrderDetails as OrderDetailsPresentation} from './order-details'

export interface OrderDetailsProps extends OrderDetailsOwnProps, OrderDetailsRuntimeProps, WithNamespaces {}

interface OrderDetailsOwnProps {
  order: wix.events.ticketing.Order
  timezone: string
}

interface OrderDetailsRuntimeProps {
  locale: string
}

const mapRuntime = ({state}: MemberPageContext): OrderDetailsRuntimeProps => ({
  locale: getFullLocale(state),
})

export const OrderDetails = connect<OrderDetailsOwnProps, OrderDetailsRuntimeProps>(mapRuntime)(
  withNamespaces()(OrderDetailsPresentation),
)
