import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../../commons/selectors/environment'
import {cancelRsvp} from '../../../actions/rsvp'
import {DetailedEvent, MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {CancelRsvpButton as Presentation} from './cancel-rsvp-button'

export interface CancelRsvpButtonProps extends CancelRsvpButtonOwnProps, CancelRsvpButtonRuntimeProps, WithNamespaces {}

interface CancelRsvpButtonOwnProps {
  event: DetailedEvent
}

interface CancelRsvpButtonRuntimeProps {
  mobile: boolean
  cancelRsvp: typeof cancelRsvp
}

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  mobile: isMobile(state),
  cancelRsvp: actions.cancelRsvp,
})

export const CancelRsvpButton = connect<CancelRsvpButtonOwnProps, CancelRsvpButtonRuntimeProps>(mapRuntime)(
  withNamespaces()(Presentation),
)
